import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const INDICATOR = 'indicator';
const DATA = 'data';
const STATIC = 'static';
const MEDICAL_RECORDS = 'medicalRecords'
const LIST = 'list'

//#region GET

export const listIndicators = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl, params);
};

export const getIndicatorData = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/${DATA}/${STATIC}/${params.typeIndicatorDefault}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl, params);
};

export const getIndicatorMedicalRecords = async ({ header, patientId }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/${MEDICAL_RECORDS}/${patientId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const listIndicatorMedicalRecords = async ({ header, patientId }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/${MEDICAL_RECORDS}/${LIST}/${patientId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

//#region POST
export const createIndicator = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const createIndicatorMedicalRecords = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/${MEDICAL_RECORDS}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const createIndicatorData = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/${DATA}/${STATIC}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT

export const updateIndicator = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateIndicatorData = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/${DATA}/${STATIC}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const saveCheckIndicator = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/check`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateIndicatorMedicalRecords = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/${MEDICAL_RECORDS}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};


//#region DELETE

export const deleteIndicator = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};

export const deleteIndicatorMedicalRecords = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/${MEDICAL_RECORDS}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion
