import React from 'react';
import CryptoJS from 'crypto-js';
import vmasker from 'vanilla-masker';
import moment from 'moment';
import isNumber from 'lodash/isNumber';
import { toastr } from 'react-redux-toastr';
import ESTADOS from '@Constants/estados';
import CIDADES from '@Constants/cidades';
import _ from 'lodash';
import Cookies from 'js-cookie';
import systemConfigs from '@Constants/systemConfigs';
import dataTypes from "@Constants/typeFields";
import colorsAvatar from '@Constants/colorAvatar';

const SECRET_KEY = 'Pb8mrfyPHGLUkNSM51L6NRRi7hIMGQKC';

export const buildFileStructure = (pastas, parentIds = [], root, idRoot, indexCategory = 0) => {
  const index = 1;
  const files = pastas
    .filter(pasta => parentIds.includes(pasta.id) || root)
    .map((pasta) => {
      const docs = [];
      return {
        ...pasta,
        idRoot,
        docs,
        indexCategory: index + indexCategory,
        files: buildFileStructure(pastas, pasta.categoryOrder, false, pasta.id, index + indexCategory)
      };
    });
  return files.length > 0 ? files : [];
};

export const aggregateByPeriod = (data, period) => {
  if (period < 1 || period > 12) {
      throw new Error("Period must be between 1 and 12.");
  }

  const result = [];
  let currentPeriod = 1;

  for (let i = 0; i < data.length; i += period) {
      const aggregated = {
          periodMonth: currentPeriod,
          year: data[i].year,
          value: 0,
          workspaceIdFilter: data[i].workspaceIdFilter
      };

      for (let j = i; j < i + period && j < data.length; j++) {
          aggregated.value += data[j].value;
      }

      result.push(aggregated);
      currentPeriod++;
  }

  return result;
}

const isNumeric = (str) => {
  if (typeof str === "number") return true; // Garante que estamos lidando com uma string
  return !isNaN(str) && !isNaN(parseFloat(str)); // Verifica se é um número válido
}

export const  calculatePercentageDifference = (value1, value2, isBigger, disabled, isDeficiency) => {

  if(disabled || (value1 === 0 &&  value2 === 0)){
    return { value: '0%', evolution: 'not', valueNumber: 0 };
  }

  if (!isNumeric(value1) || !isNumeric(value2)) {
    throw new Error("The values provided are not valid numbers.");
  }

  let percentageDifference = Math.abs(((value1*100)/value2)-100);
  if(value2 === 0){
    if(isDeficiency) percentageDifference = value1;
    else percentageDifference = Math.abs(value1*100)/1;
  }

  const value = (value1 > value2) ? `${percentageDifference.toFixed(0)}%` : `${percentageDifference.toFixed(0)}%`
  const evolution = (value1 > value2) ? 'up' : 'down'

  return { value, evolution, valueNumber: (evolution === 'up' ? 1 : -1) * percentageDifference };
}

export const getNamePeriod = (period, periodMonth) => {
  switch (period) {
    case 1:
      return getNameMonth(periodMonth);
    case 2:
      return `${periodMonth}° Bimestre`;
    case 3:
      return `${periodMonth}° Trimestre`;
    case 4:
      return `${periodMonth}° Quadrimestre`;
    case 6:
      return `${periodMonth}° Semestre`;
    case 12:
      return periodMonth;
    default:
      return '';
  }
}

export const sumObjects = (objects) => {
  const result = {};

  for (const obj of objects) {
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              const numericKey = Number(key);
              result[numericKey] = (result[numericKey] || 0) + obj[key];
          }
      }
  }

  return result;
}

export const mapMonthsToNumbers = (data, period) => {
  const monthMapping = {
    Jan: 1,
    Fev: 2,
    Mar: 3,
    Abr: 4,
    Mai: 5,
    Jun: 6,
    Jul: 7,
    Ago: 8,
    Set: 9,
    Out: 10,
    Nov: 11,
    Dez: 12
  };

  const result = {};
  for (const [month, value] of Object.entries(data)) {
    if (monthMapping[month] !== undefined) {
      result[monthMapping[month]] = value;
    }
  }
  return groupByPeriod(result, period) ;
}

export const ruleOfThree = (a, b, c) => {
  if (!a) return null;
  if (a === 0) {
    return null
  }
  const result = (b * c) / a;
  return parseFloat(result.toFixed(2));
}

function groupByPeriod(data, period) {
  if (period < 1 || period > 12) {
      throw new Error("Period must be between 1 and 12.");
  }

  const result = {};
  const keys = Object.keys(data).map(Number); // Garantir que as chaves sejam numéricas
  let periodIndex = 1;

  for (let i = 0; i < keys.length; i += period) {
      result[periodIndex] = 0;

      for (let j = i; j < i + period && j < keys.length; j++) {
          const value = data[keys[j]];
          if (value !== null) {
              result[periodIndex] += value;
          }
      }

      periodIndex++;
  }

  return result;
}

export const processData = (data, indicatorData, currentYearSelected, period, typeVisualization) => {
  let dataByDate = {};

  if (indicatorData.data) {

    indicatorData.data.forEach(item => {
      const year = item.year;
      dataByDate[year] = item.data;
    });

    const dataYearObject = {};

    dataByDate[currentYearSelected].forEach(item => {
      dataYearObject[item.key] = mapMonthsToNumbers(item, period)
    });

    const newMap = data.map(item => {
      const key = item.key;
      let value = dataYearObject[item.workspaceId];
      if(typeVisualization === 'common') {
        value = sumObjects(Object.values(dataYearObject))
      }

      const attendanceFormat = aggregateByPeriod(item.attendances, period)
      const attendances = attendanceFormat.map(attendance => {
        return {
          ...attendance,
          value: ruleOfThree(value[attendance.periodMonth], 100, attendance.value),
        };
      });

      return {
        ...item,
        attendances
      };
    });
    return newMap

  } else {
    return data
  }
};

export const getTitleSeries = (ruleField, item, typeIndicator) => {
  let titleSeries = '';
  
  if (typeIndicator === 'variation') return 'Desempenho';
  if (ruleField.rule === 'allResults') titleSeries = '';
  else if (ruleField.typeRule === 'qualifier') return `Qualificação ${ruleField.title} ${ruleField.searchName}`;
  else if (ruleField.typeRule === 'qualifierDeficiency') return `Deficiência ${ruleField.title} ${ruleField.searchName}`;
  else titleSeries = `Valor ${ruleField.title} ${ruleField.searchName}`;

  if (titleSeries === '') return `Valor ${item.value}`;
  else if (item.group) return titleSeries;
  return 'Outros valores';
}

export const getNameMonth = (month) => {
  switch (month) {
    case 1:
      return 'Janeiro';
    case 2:
      return 'Fevereiro';
    case 3:
      return 'Março';
    case 4:
      return 'Abril';
    case 5:
      return 'Maio';
    case 6:
      return 'Junho';
    case 7:
      return 'Julho';
    case 8:
      return 'Agosto';
    case 9:
      return 'Setembro';
    case 10:
      return 'Outubro';
    case 11:
      return 'Novembro';
    case 12:
      return 'Dezembro';
    default:
      return '';
  }
}

export const getPeriodDates = (type, value, year) => {
  let startDate;
  let endDate;
  if(!value && !year){
    return {};
  }
  if(!value){
    return {
      startDate: moment([year, 0]),
      endDate: moment([year, 11]).endOf('month'),
    }
  }

  switch (type) {
    case 1:
      startDate = moment([year, value - 1]); // Mês começa de 0 em moment
      endDate = startDate.clone().endOf('month');
      break;
    case 2:
      startDate = moment([year, (value - 1) * 2]);
      endDate = startDate.clone().add(1, 'month').endOf('month');
      break;
    case 3:
      startDate = moment([year, (value - 1) * 3]);
      endDate = startDate.clone().add(2, 'months').endOf('month');
      break;
    case 4:
      startDate = moment([year, (value - 1) * 4]);
      endDate = startDate.clone().add(3, 'months').endOf('month');
      break;
    case 6:
      startDate = moment([year, (value - 1) * 6]);
      endDate = startDate.clone().add(5, 'months').endOf('month');
      break;
    case 12:
      startDate = moment([year, 0]);
      endDate = moment([year, 11]).endOf('month');
      break
    default:
      throw new Error("Tipo de período inválido.");
  }
  return { startDate: startDate.format(), endDate: endDate.format() };
}

export const getPeriodFromDate = (type, date) => {
  const mDate = moment(date);
  const month = mDate.month();
  let period;

  switch (type) {
    case 1:
      period = month + 1; 
      break;
    case 2:
      period = Math.floor(month / 2) + 1; 
      break;
    case 3:
      period = Math.floor(month / 3) + 1; 
      break;
    case 4:
      period = Math.floor(month / 4) + 1; 
      break;
    case 6:
      period = Math.floor(month / 6) + 1; 
      break;
    case 12:
      period = Math.floor(month / 12) + 1; 
      break
    default:
      throw new Error("Tipo de período inválido.");
  }

  return period;
}

export const hexToRgba = (hex, transparency) => {
  hex = hex.replace('#', '');

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${transparency})`;
}

export const  classificationQualifier = (valueParam, qualifier, isDeficiencyQualifier, {scoreMax}) => {
  const isPercentage = qualifier.typeInterval === 'percentage';
  let value = Number(Number(valueParam).toFixed(2));
  if(isPercentage && !isDeficiencyQualifier){
    value = (value * 100)/scoreMax;
  } 
  if(qualifier.rules){
    for (const rule of qualifier.rules) {
      const order = rule.initialProportion < rule.endProportion;
      if (order ? (value >= rule.initialProportion && value <= rule.endProportion) : (value <= rule.initialProportion && value >= rule.endProportion)) {
          return {
              color: rule.color || '#0a0a0a',
              backgroundColor: rule.backgroundColor,
              backgroundColorEdit: hexToRgba(rule.backgroundColor, 0.5),
              name: rule.name,
              details: rule.details,
              code: rule.code,
              codeLabel: rule.codeLabel,
          };
      }
  }
  }
  return {
      color: '#6e706e',
      backgroundColor: '#dedede',
      backgroundColorEdit: '#dedede',
      name: 'Não Qualificado',
      details: `Não foi possível classificar o valor ${isDeficiencyQualifier ? 'da deficiencia' : 'do resultado'}`,
      code: 'NQ',
      isNotQualifier: true,
  };
};

export const findLargestSmallest = (list) => {
  if (list.length === 0) {
    return { largest: {}, smallest: {}, now: {}, first: {} };
  }

  let largest = list[0];
  let smallest = list[0];
  let now = list[0];
  let first = list[0];

  for (let i = 1; i < list.length; i++) {
    if (list[i].value > largest.value) {
      largest = list[i];
    }
    if (list[i].value < smallest.value) {
      smallest = list[i];
    }
    if (list[i].date > now.date) {
      now = list[i];
    }
    if (list[i].date < first.date) {
      first = list[i];
    }
  }

  return { largest: largest, smallest: smallest, now, first };
}

export const pointsCounter = (field, value, valueCheck = false) => {
  const options = field.radioGroup ? field.radioGroup.options : [];
  let points = null;
  if (valueCheck) options.map((opt, index) => (
    opt.radioGroupModelVersionOptionId === value && opt.points !== null && opt.points !== undefined
      ? points = opt.points : null
  ));
  else options.map((opt, index) => (
    opt.selected && opt.points !== null && opt.points !== undefined ? points = opt.points : null
  ));
  return points;
}

export const validateFieldsScoresDataType = (fields) => {
  let fieldPoints = [];
  let key = 0;
  fields.map((field) => {
    let hasPoints = false;
    switch (field.dataType) {
      case dataTypes.SCORES_TYPE:
      case dataTypes.CHECKBOX_GROUP_TYPE:
      case dataTypes.RADIO_GROUP_TYPE:
      case dataTypes.INT_TYPE:
      case dataTypes.FLOAT_TYPE:
      case dataTypes.DECIMAL_TYPE:
      case dataTypes.SLIDER_TYPE:
        hasPoints = true;
        break;
      case dataTypes.CHECKBOX_TYPE:
        if (field.points != null) hasPoints = true;
        break;
    }
    if (hasPoints) {
      key++;
      fieldPoints = [...fieldPoints, { ...field, key: key }]; //New list of fields that holds points
    }
  });
  return fieldPoints;
};


export const isEmptyObject = (data) =>
  Object.keys(data).length === 0 && data.constructor === Object;
export const maxLength = (max) => (value) =>
  value && value.length > max
    ? `Deve ter ${max} caracteres ou menos!`
    : undefined;
export const minLength = (min) => (value) =>
  value && value.length < min
    ? `Deve ter ${min} caracteres ou mais!`
    : undefined;
export const minValue = (min) => (value) =>
  value && value.length < min ? `Deve ter pelo menos ${min}` : undefined;
export const exact2Caracter = (value) =>
  value && value.length !== 2 ? `Deve ter 2 caracteres!` : undefined;
export const number = (value) =>
  value && isNaN(Number(value)) ? 'Deve ser um número!' : undefined;
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Endereço de email inválido'
    : undefined;
export const site = (value) =>
  value &&
  !/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
    value
  )
    ? 'Url inválido! (https://exemplo.com)'
    : undefined;

export const formatCPF = (cpf = '') => {
  let formatCPF = cpf.replace(/\D/g, '');
  formatCPF = formatCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  return formatCPF;
};
export const tooOld = (value) =>
  value && value > 65 ? 'Você pode ser muito velho para isso!' : undefined;
export const aol = (value) =>
  value && /.+@aol\.com/.test(value)
    ? 'Sério? Você ainda usa a AOL para o seu email?'
    : undefined;
export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Apenas caracteres alfanuméricos!'
    : undefined;
export const required = (value) =>
  value ? undefined : 'Este campo é obrigatório.';
export const requiredChange = (value, allValues, props, name) => {
  const { initialValues, name: formName } = props;
  let error = false;
  const unformattedCPF1 = unformat(initialValues.cpf);
  const unformattedCPF2 = unformat(allValues.cpf);
  if (!formName.includes('update')) {
    if (name === 'cpf') {
      if (
        // initialValues.cpf &&
        unformattedCPF1 === unformattedCPF2 &&
        initialValues.email !== allValues.email &&
        !initialValues.email
      ) {
        error = true;
      }
    } else if (name === 'email') {
      if (
        // initialValues.email &&
        unformattedCPF1 !== unformattedCPF2 &&
        initialValues.email === allValues.email &&
        !unformattedCPF1
      ) {
        error = true;
      }
    }
  }
  return error ? 'É necessário alterar esse campo' : undefined;
};
export const requiredValue = (value) =>
  value > 0 ? undefined : 'Este campo é obrigatório.';
export const maxLength15 = maxLength(15);
export const minLength6 = minLength(6);
export const minValue18 = minValue(18);
export const minValue6 = minValue(6);

export const maskOnlyNumber = (value) => vmasker.toNumber(value);
export const maskDecimalNumber = (value) => vmasker.toMoney(value); //999.999,00
export const maskDateAndHour = (value) =>
  vmasker.toPattern(value, '99/99/9999 99:99');
export const maskDateOnly = (value) => vmasker.toPattern(value, '99/99/9999');
export const maskHourOnly = (value) => vmasker.toPattern(value, '99:99');

export const formatEditorTag = (content) =>
  content.replace(/\[\[/g, '@(').replace(/\]\]/g, ')@');
export const formatServerTag = (content) =>
  content.replace(/@\(/g, '[[').replace(/\)@/g, ']]');

export const getTokenStorage = () => {
  const auth = JSON.parse(getCookie('auth') || '{}');
  // Do something before request is sent
  if (auth && auth.hasOwnProperty('token')) {
    return `Bearer ${auth.token}`;
  }

  return null;
};

export const unformat = (value) => {
  if (value && value !== '') return value.replace(/[/.:-]/g, '');
  return null;
};

export const formatInstallment = (
  packagePrice,
  usersQuantity,
  numberInstallments
) => {
  const installmentPrice = (
    (packagePrice * usersQuantity) /
    (100 * numberInstallments)
  ).toFixed(2);
  return `${numberInstallments}x de R$ ${installmentPrice}`;
};

export const formatReal = (number) => {
  if (typeof number !== 'number') return 0;

  // Create our number formatter.
  let formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });

  return formatter.format(number);
};

export const formatFloat = (number) => {
  try {
    if (`${number}`.indexOf('.') === -1) return number;

    let numberInteger = `${number}`.split('.')[0];
    let temp = `${number}`.split('.')[1];
    temp = temp.slice(0, 2);

    return parseFloat(`${numberInteger}.${temp}`);
  } catch (exception) {
    return null;
  }
};

export const removedAccent = (key) => {
  if (!key || !key.length) return key;

  return key.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); //this remove accents
};

export const encrypt = (dataString) => {
  if (!dataString) return;

  const cypherText = CryptoJS.AES.encrypt(dataString, SECRET_KEY);
  let newCypherText = cypherText.toString().replace(/\//g, 'encrypt');
  return newCypherText;
};

export const decrypt = (dataString) => {
  if (!dataString) return;
  let newCypherText = dataString.replace(/encrypt/g, '/');
  const bytes = CryptoJS.AES.decrypt(newCypherText, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const sanitizeSearchName = (item, value) => {
  const sanitizedSearch = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const sanitizedName = item.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const regex = new RegExp(`^(.*?(${sanitizedSearch})[^$]*)$`, 'i');

  return regex.test(sanitizedName);
};

export const sanitizeSearch = (item, value) => {
  const sanitizedSearch = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const sanitizedName = item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const regex = new RegExp(`^(.*?(${sanitizedSearch})[^$]*)$`, 'i');

  return regex.test(sanitizedName);
};

/**
 * @description Função que ordena em ordem ASC | DESC
 * @param {object} object recebe um objeto qualuqer
 * @param {string} type atributo do objeto
 * @param {string} order asc | desc
 * @returns {object}
 */
export const orderByObject = (object, type, order) => {
  if (!type || typeof type !== 'string') {
    console.warn(
      'Warning!',
      'type is undefined or not is string, please check the references'
    );
    return object;
  }

  if (order === 'desc')
    return object.sort((a, b) =>
      b[type] < a[type] ? -1 : b[type] === a[type] ? 0 : 1
    );

  return object.sort((a, b) =>
    a[type] < b[type] ? -1 : a[type] === b[type] ? 0 : 1
  );
};

/**
 * @description Função que retorna o objeto que não contem o mesmo atributo
 * @param {object} list objeto qualquer
 * @param {string} type atributo do objeto
 * @returns {object}
 */
export const distinctObject = (list, type) => {
  const unique = {};
  const distinct = [];

  list.map((item) => {
    if (typeof unique[item[type]] === 'undefined') {
      distinct.push(item);
    }

    return (unique[item[type]] = 0);
  });

  return distinct;
};

/**
 * @description Função que retorna o valor não duplicados de um objeto
 * @param {object} list objeto qualquer
 * @param {string} type atributo do objeto
 * @returns {object}
 */
export const distinct = (list, type) => {
  const unique = {};
  const distinct = [];

  list.map((item) => {
    if (typeof unique[item[type]] === 'undefined') {
      distinct.push(item[type]);
    }

    return (unique[item[type]] = 0);
  });

  return distinct;
};

/**
 * @description Função que retornar as 2 letras do nome informado
 * @param {string} string recebe uma cadeia de caracteres
 * @returns {string}
 */
export const getNameInitials = (string) => {
  let newString = string.split(' ');
  let anwser = '';

  if (newString.length > 1) {
    let firstName = newString[0].toUpperCase().substr(0, 1);
    let lastName = newString[newString.length - 1].toUpperCase().substr(0, 1);
    anwser = `${firstName}${lastName}`;
  } else {
    newString = newString[0].split('');
    anwser = `${newString[0].toUpperCase().substr(0, 1)}${newString[1]
      .toUpperCase()
      .substr(0, 1)}`;
  }

  return anwser;
};

/**
 * @description Gerar UUID v4
 */
export const generateUUID = () => {
  // http://www.ietf.org/rfc/rfc4122.txt
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  const uuid = s.join('');
  return uuid;
};

export const getAllElementsWithAttributeByEditor = () => {
  let matchingElements = [];
  const allElements = document.querySelectorAll('[data-block="true"]');

  for (let i = 0, n = allElements.length; i < n; i++) {
    if (allElements[i].getAttribute('data-block') !== null) {
      // Element exists with attribute. Add to array.
      matchingElements.push(allElements[i]);
    }
  }
  return matchingElements;
};

export const getElement = (conditionKey, nodeElements = []) => {
  let element = {};

  // eslint-disable-next-line
  nodeElements.map((item, key) => {
    if (item) {
      let offSetKey = item.dataset.offsetKey;
      let setKey = offSetKey.length ? offSetKey.split('-') : [];

      if (setKey[0] === conditionKey) element = item;
    }
  });

  return element;
};

export const normalizeCodeValidation = (value) => {
  if (!value) return value;

  const onlyString = value.replace(/[^\w]/g, '');
  const numLength = onlyString.length;

  const code = numLength <= 6 ? onlyString : onlyString.substring(0, 6);

  let newCode = '';
  for (let i = 0; i < code.length; i++) {
    newCode += ' ' + code[i].toUpperCase();
  }
  return newCode;
};


export const normalizeCPF = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, '');
  const numLength = onlyNumbers.length;

  if (numLength <= 3) return onlyNumbers;
  else if (numLength <= 6)
    return `${onlyNumbers.slice(0, 3)}.${onlyNumbers.slice(3, 6)}`;
  else if (numLength <= 9)
    return `${onlyNumbers.slice(0, 3)}.${onlyNumbers.slice(
      3,
      6
    )}.${onlyNumbers.slice(6, 9)}`;
  else
    return `${onlyNumbers.slice(0, 3)}.${onlyNumbers.slice(
      3,
      6
    )}.${onlyNumbers.slice(6, 9)}-${onlyNumbers.slice(9, 11)}`;
};

export const normalizeCNPJ = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, '');
  const numLength = onlyNumbers.length;

  if (numLength <= 2) return onlyNumbers;
  else if (numLength <= 5)
    return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(2, 5)}`;
  else if (numLength <= 8)
    return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(
      2,
      5
    )}.${onlyNumbers.slice(5, 8)}`;
  else if (numLength <= 12)
    return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(
      2,
      5
    )}.${onlyNumbers.slice(5, 8)}/${onlyNumbers.slice(8, 12)}`;
  else
    return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(
      2,
      5
    )}.${onlyNumbers.slice(5, 8)}/${onlyNumbers.slice(
      8,
      12
    )}-${onlyNumbers.slice(12, 14)}`;
};

export const validatePhone = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, '');
  const numLength = onlyNumbers.length;
  if (numLength !== 11 && numLength !== 10) return 'Número de celular inválido';
  return undefined;
};

export const normalizeCodNumber = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, '');
  const numLength = onlyNumbers.length;

  if (numLength <= 3) return `+${onlyNumbers}`;
  return `+${onlyNumbers.slice(0, 3)}`;
};

export const normalizePhone = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, '');
  const numLength = onlyNumbers.length;

  if (numLength <= 2) return `(${onlyNumbers.slice(0, 2)}`;
  else if (numLength <= 6)
    return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(2, 6)}`;
  else if (numLength <= 10)
    return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(
      2,
      6
    )}-${onlyNumbers.slice(6, 10)}`;
  else
    return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(
      2,
      7
    )}-${onlyNumbers.slice(7, 11)}`;
};

export const normalizeNumber = (value) => {
  if (!value) return value;
  const onlyNumbers = value.replace(/[^\d]/g, '');
  return onlyNumbers;
};

export const normalizeData = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, '');
  const numLength = onlyNumbers.length;

  if (numLength <= 2) return onlyNumbers;
  else if (numLength <= 4) {
    const first = onlyNumbers.slice(0, 2);
    const second = onlyNumbers.slice(2, 4);

    if (Number(first) <= 12 && Number(second) > 12) return `${second}/${first}`;
    else return `${first}/${second}`;
  } else {
    const first = onlyNumbers.slice(0, 2);
    const second = onlyNumbers.slice(2, 4);
    const third = onlyNumbers.slice(4, 8);

    if (Number(first) <= 12 && Number(second) > 12)
      return `${second}/${first}/${third}`;
    else return `${first}/${second}/${third}`;
    // return `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(
    //   2,
    //   4
    // )}/${onlyNumbers.slice(4, 8)}`;
  }
};

export const normalizeHour = (value) => {
  if (!value) return value;

  const onlyNumbers = value.toString().replace(/[^\d]/g, '');
  const numLength = onlyNumbers.length;

  if (numLength <= 2) return onlyNumbers;
  else if (numLength <= 4)
    return `${onlyNumbers.slice(0, 2)}:${onlyNumbers.slice(2, 4)}`;
  else
    return `${onlyNumbers.slice(0, 2)}:${onlyNumbers.slice(
      2,
      4
    )}:${onlyNumbers.slice(4, 6)}`;
};

export const normalizeCEP = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, '');
  const numLength = onlyNumbers.length;

  if (numLength <= 5) return onlyNumbers;
  else return `${onlyNumbers.slice(0, 5)}-${onlyNumbers.slice(5, 8)}`;
};

export const validateCPF = (value) => {
  if (!value) return 'Por favor, digite um CPF válido.';

  // Removing special characters from value
  value = value.replace(/[^\d]/g, '');

  // Checking value to have 11 digits only
  if (value.length !== 11) return 'Por favor, digite um CPF válido.';

  let sum = 0;
  let firstCN = 0;
  let secondCN = 0;
  let i = 0;

  firstCN = parseInt(value.substring(9, 10), 10);
  secondCN = parseInt(value.substring(10, 11), 10);

  let checkResult = (sum, cn) => {
    let result = (sum * 10) % 11;
    if (result === 10 || result === 11) {
      result = 0;
    }
    return result === cn;
  };

  // Checking for dump data
  if (
    value === '' ||
    value === '00000000000' ||
    value === '11111111111' ||
    value === '22222222222' ||
    value === '33333333333' ||
    value === '44444444444' ||
    value === '55555555555' ||
    value === '66666666666' ||
    value === '77777777777' ||
    value === '88888888888' ||
    value === '99999999999'
  ) {
    return 'Por favor, digite um CPF válido.';
  }

  // Step 1 - using first Check Number:
  for (i = 1; i <= 9; i++) {
    sum = sum + parseInt(value.substring(i - 1, i), 10) * (11 - i);
  }

  // If first Check Number (CN) is valid, move to Step 2 - using second Check Number:
  if (!checkResult(sum, firstCN)) return 'Por favor, digite um CPF válido.';

  sum = 0;
  for (i = 1; i <= 10; i++) {
    sum = sum + parseInt(value.substring(i - 1, i), 10) * (12 - i);
  }

  if (!checkResult(sum, secondCN)) return 'Por favor, digite um CPF válido.';

  return undefined;
};

export const validateCNPJ = (value) => {
  if (!value) return 'Por favor, digite um CNPJ válido.';

  value = value.replace(/[^\d]+/g, '');

  if (value.length != 14) return 'Por favor, digite um CNPJ válido.';

  // Checking for dump data
  if (
    value == '00000000000000' ||
    value == '11111111111111' ||
    value == '22222222222222' ||
    value == '33333333333333' ||
    value == '44444444444444' ||
    value == '55555555555555' ||
    value == '66666666666666' ||
    value == '77777777777777' ||
    value == '88888888888888' ||
    value == '99999999999999'
  )
    return 'Por favor, digite um CNPJ válido.';

  // Valida DVs
  let size = value.length - 2;
  let numbers = value.substring(0, size);
  let digits = value.substring(size);

  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(0)) return 'Por favor, digite um CNPJ válido.';

  size = size + 1;
  numbers = value.substring(0, size);
  sum = 0;
  pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(1)) return 'Por favor, digite um CNPJ válido.';

  return undefined;
};

export const validateFormatDate = (value) => {
  if (!value) return value;
  if (value) {
    const isValidDate = moment(value, 'DD/MM/YYYY', true).isValid();
    if (!isValidDate) return 'Data inválida';
  }
  value = value.replace(/[^\d]/g, '');

  if (value.length !== 8) return 'Por favor, digite uma data válida.';
};

export const validateFormatCEP = (value) => {
  if (!value) return value;

  value = value.replace(/[^\d]/g, '');

  if (value.length !== 8) return 'Por favor, digite um CEP válido.';
};

export const formatDateAmerican = (value) => {
  if (typeof value !== 'string') return value;

  let temp = value.split('/');

  if (temp.length !== 3) return value;

  return temp.reverse().join('-');
};

export const removeMaskOnlyNumber = (value) => {
  if (typeof value !== 'string') return value;

  return value.replace(/[^\d]/g, '');
};

export const managerExceptionURI = (error, generatorFunction, customMsg) => {
  if (customMsg) return generatorFunction({ message: customMsg });
  if (error) {
    if (typeof error.status === 'number')
      return generatorFunction({ message: error.userFeedback });
  }

  return generatorFunction({
    message: 'Verifique a conexão da sua internet e tente novamente!'
  });
};

export const managerExceptionURIMessage = (props, config) => {
  const { error, customMsg } = props;
  const { toastrOptions } = config || {};
  let { title, message, options } = toastrOptions || {};

  title = title || 'Atenção';
  options = options || {};

  if (customMsg) {
    message = message || customMsg;
    toastr.warning(title, message, options);
    return;
  }

  if (error) {
    if (typeof error.status === 'number') {
      message = message || error.userFeedback;
      toastr.warning(title, message, options);
      return;
    }
  }

  message = message || 'Verifique a conexão da sua internet e tente novamente!';
  toastr.warning(title, message, options);
};

export const parseHorizontalTableToVertical = (table) => {
  const horizontal = { ...table };

  const vertical = {
    rows: []
  };

  if (horizontal.tableModelId) vertical.tableModelId = horizontal.tableModelId;
  if (horizontal.tableModelVersionId)
    vertical.tableModelVersionId = horizontal.tableModelVersionId;
  if (horizontal.versionTag) vertical.versionTag = horizontal.versionTag;

  for (const column of horizontal.columns) {
    const verticalRow = {
      dataType: column.dataType,
      order: column.order,
      name: column.name,
      size: column.size,
      columns: []
    };

    let isTmp = false;

    if (column.tableModelVersionColumnId) {
      verticalRow.tableModelVersionRowId = column.tableModelVersionColumnId;
    } else if (column.tmpHash) {
      verticalRow.tmpHash = column.tmpHash;
      isTmp = true;
    }

    for (const row of horizontal.rows) {
      if (isTmp)
        verticalRow.columns.push({
          value: row[column.tmpHash],
          order: row.order
        });
      else
        verticalRow.columns.push({
          value: row[column.tableModelVersionColumnId],
          order: row.order
        });
    }

    vertical.rows.push(verticalRow);
  }

  return vertical;
};

export const parseVerticalTableToHorizontal = (table) => {
  const vertical = { ...table };

  const horizontal = {
    columns: [],
    rows: []
  };

  const indexedRowsByOrder = [];

  if (vertical.tableModelId) horizontal.tableModelId = vertical.tableModelId;
  if (vertical.tableModelVersionId)
    horizontal.tableModelVersionId = vertical.tableModelVersionId;
  if (vertical.versionTag) horizontal.versionTag = vertical.versionTag;

  for (const row of vertical.rows) {
    const horizontalColumn = {
      dataType: row.dataType,
      order: row.order,
      name: row.name,
      size: row.size
    };

    let isTmp = false;

    if (row.tableModelVersionRowId) {
      horizontalColumn.tableModelVersionColumnId = row.tableModelVersionRowId;
    } else if (row.tmpHash) {
      horizontalColumn.tmpHash = row.tmpHash;
      isTmp = true;
    }

    for (const verticalColumn of row.columns) {
      if (!indexedRowsByOrder[verticalColumn.order]) {
        if (isTmp)
          indexedRowsByOrder[verticalColumn.order] = {
            [row.tmpHash]: verticalColumn.value,
            order: verticalColumn.order
          };
        else
          indexedRowsByOrder[verticalColumn.order] = {
            [row.tableModelVersionRowId]: verticalColumn.value,
            order: verticalColumn.order
          };
      } else {
        if (isTmp)
          indexedRowsByOrder[verticalColumn.order] = {
            ...indexedRowsByOrder[verticalColumn.order],
            [row.tmpHash]: verticalColumn.value
          };
        else
          indexedRowsByOrder[verticalColumn.order] = {
            ...indexedRowsByOrder[verticalColumn.order],
            [row.tableModelVersionRowId]: verticalColumn.value
          };
      }
    }

    horizontal.columns.push(horizontalColumn);
  }

  for (const indexedRow of indexedRowsByOrder) {
    if (indexedRow !== null && indexedRow !== undefined) {
      horizontal.rows.push(indexedRow);
    }
  }

  return horizontal;
};

export const toggleSelectedIndex = (dataSource, idSource) => {
  const selectedIndex = dataSource.indexOf(idSource);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(dataSource, idSource);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(dataSource.slice(1));
  } else if (selectedIndex === dataSource.length - 1) {
    newSelected = newSelected.concat(dataSource.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      dataSource.slice(0, selectedIndex),
      dataSource.slice(selectedIndex + 1)
    );
  }

  return newSelected;
};


export const snackBarManagerExceptionURIMessage = (props) => {
  const { error, customMsg, duration } = props;

  const autoDuration = duration || 3500;
  if (customMsg)
    return { message: customMsg, variant: 'error', autoHideDuration: autoDuration };

  if (error && typeof error.status === 'number')
    return {
      message: error.userFeedback,
      variant: 'error',
      autoHideDuration: autoDuration
    };

  return {
    message: 'Verifique a conexão da sua internet e tente novamente!',
    variant: 'error',
    autoHideDuration: autoDuration
  };
};

export const getPeriodDatetimeDetails = (startDatetime, endDatetime) => {
  const start = moment(startDatetime, 'YYYY-MM-DD HH:mm:ss');
  const end = moment(endDatetime, 'YYYY-MM-DD HH:mm:ss');

  const yearsDiff = end.diff(start, 'years');
  const years = yearsDiff;
  const yearsDuration = moment.duration(yearsDiff, 'years');
  const yearsAsMonths = yearsDuration.asMonths();

  const monthsDiff = end.diff(start, 'months');
  const months = monthsDiff - yearsAsMonths;
  const monthsDuration = moment.duration(monthsDiff, 'months');
  const monthsAsDays = monthsDuration.asDays();

  const daysDiff = end.diff(start, 'days');
  let days = daysDiff - monthsAsDays;
  const daysDuration = moment.duration(daysDiff, 'days');
  const daysAsHours = daysDuration.asHours();

  const hoursDiff = end.diff(start, 'hours');
  const hours = hoursDiff - daysAsHours;
  const hoursDuration = moment.duration(hoursDiff, 'hours');
  const hoursAsMinutes = hoursDuration.asMinutes();

  const minutesDiff = end.diff(start, 'minutes');
  const minutes = minutesDiff - hoursAsMinutes;
  const minutesDuration = moment.duration(minutesDiff, 'minutes');
  const minutesAsSeconds = minutesDuration.asSeconds();

  const secondsDiff = end.diff(start, 'seconds');
  const seconds = secondsDiff - minutesAsSeconds;

  if (days < 0) days = 0;

  return { years, months, days, h: hours, m: minutes, s: seconds };
};

export const getPeriodDateDetails = (startDate, endDate) => {
  const start = moment(startDate, 'YYYY-MM-DD');
  const end = moment(endDate, 'YYYY-MM-DD');

  const yearsDiff = end.diff(start, 'years');
  const years = yearsDiff;
  const yearsDuration = moment.duration(yearsDiff, 'years');
  const yearsAsMonths = yearsDuration.asMonths();

  const monthsDiff = end.diff(start, 'months');
  const months = monthsDiff - yearsAsMonths;
  const monthsDuration = moment.duration(monthsDiff, 'months');
  const monthsAsDays = monthsDuration.asDays();

  const daysDiff = end.diff(start, 'days');
  const days = daysDiff - monthsAsDays;

  return { years, months, days };
};

export const getPeriodTimeDetails = (startTime, endTime) => {
  const start = moment(startTime, 'HH:mm:ss');
  const end = moment(endTime, 'HH:mm:ss');

  const hoursDiff = end.diff(start, 'hours');
  const hours = hoursDiff;
  const hoursDuration = moment.duration(hoursDiff, 'hours');
  const hoursAsMinutes = hoursDuration.asMinutes();

  const minutesDiff = end.diff(start, 'minutes');
  const minutes = minutesDiff - hoursAsMinutes;
  const minutesDuration = moment.duration(minutesDiff, 'minutes');
  const minutesAsSeconds = minutesDuration.asSeconds();

  const secondsDiff = end.diff(start, 'seconds');
  const seconds = secondsDiff - minutesAsSeconds;

  return { h: hours, m: minutes, s: seconds };
};

export const getPeriodHourDetails = (startHour, endHour) => {
  let hour = null;
  let min = null;
  let sec = null;

  if (isNumber(startHour.h) && isNumber(endHour.h) && startHour.h < endHour.h)
    hour = endHour.h - startHour.h;

  if (isNumber(startHour.m) && isNumber(endHour.m) && startHour.m < endHour.m)
    min = endHour.m - startHour.m;

  if (isNumber(startHour.s) && isNumber(endHour.s) && startHour.s < endHour.s)
    sec = endHour.s - startHour.s;

  return { h: hour, m: min, s: sec };
};

export const removeDateZ = (date) => {
  if (!date) return '';
  return date.replace('Z', '');
} 

export const shouldLoadTimelineAttendance = (attendance, filter) => {
  if (!filter) return true;
  if (filter === 'all') return true;
  if (attendance && attendance[filter] && attendance[filter].length)
    return true;
  else if (attendance && attendance.sections) {
    for (const section of attendance.sections) {
      if (section.name === filter) return true;
    }
  }
  return false;
};

export const extractBetweenKeys = (stringValue) => {
  return stringValue ? stringValue.match(/{(.*?)}/g) : null;
};

export const formatIntervalDuration = (intervalDuration) => {
  const { years, months, days, h, m, s } = intervalDuration;

  let postValue = '';

  let openParentheses = false;
  let closeParentheses = false;

  if (years) {
    const yearSufix = years > 1 ? 'anos' : 'ano';
    postValue = `${postValue} ${years} ${yearSufix}`;
    openParentheses = true;
  }

  if (months) {
    const monthSufix = months > 1 ? 'meses' : 'mês';
    if (!openParentheses) {
      postValue = `${postValue} ${months} ${monthSufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${months} ${monthSufix}`;
  }

  if (days) {
    const daySufix = days > 1 ? 'dias' : 'dia';
    if (!openParentheses) {
      postValue = `${postValue} ${days} ${daySufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${days} ${daySufix}`;
  }

  if (h) {
    const hSufix = h > 1 ? 'horas' : 'hora';
    if (!openParentheses) {
      postValue = `${postValue} ${h} ${hSufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${h} ${hSufix}`;
  }

  if (m) {
    const mSufix = m > 1 ? 'minutos' : 'minuto';
    if (!openParentheses) {
      postValue = `${postValue} ${m} ${mSufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${m} ${mSufix}`;
  }

  if (s) {
    const sSufix = s > 1 ? 'segundos' : 'segundo';
    if (!openParentheses) {
      postValue = `${postValue} ${s} ${sSufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${s} ${sSufix}`;
    closeParentheses = true;
  }

  if (!closeParentheses && openParentheses) postValue = `${postValue}`;

  return postValue;
};

export const formatTimeIntervalDuration = (intervalDuration) => {
  let value = '';

  if (intervalDuration) {
    const { h, m, s } = intervalDuration;

    let openParentheses = false;
    let closeParentheses = false;

    if (h) {
      const hSufix = h > 1 ? 'horas' : 'hora';
      value = `${value} ${h} ${hSufix}`;
      openParentheses = true;
    }

    if (m) {
      const mSufix = m > 1 ? 'minutos' : 'minuto';
      if (!openParentheses) {
        value = `${value} ${m} ${mSufix}`;
        openParentheses = true;
      } else value = `${value} e ${m} ${mSufix}`;
    }

    if (s) {
      const sSufix = s > 1 ? 'segundos' : 'segundo';
      if (!openParentheses) {
        value = `${value} ${s} ${sSufix}`;
        openParentheses = true;
      } else value = `${value} e ${s} ${sSufix}`;
      closeParentheses = true;
    }

    if (!closeParentheses && openParentheses) value = `${value}`;
  }

  return value;
};

export const extractBetweenAt = (stringValue) => {
  // eslint-disable-next-line
  return stringValue ? stringValue.match(/\@(.*?)\@/g) : null;
};

export const changeFormValues = (change, focus, form, fields, values) => {
  for (let field of fields) {
    change(form, field, values[field]);
    if (field !== 'active') focus(form, field);
  }
};

export const focusFields = (focus, form, fields, values) => {
  for (let field of fields) {
    if (!values[field]) {
      focus(form, field);
    }
  }
};

export const generateCouncilOptions = (councils) => {
  if (councils)
    return councils.map((council) => {
      if (council.initials === '-') {
        return {
          label: `${council.name}`,
          value: council.id
        };
      }
      return {
        label: `${council.name} (${council.initials})`,
        value: council.id
      };
    });
  return [];
};

export const generateRegionOptions = () => {
  let options = [];
  for (let i = 1; i <= 27; i++) {
    options.push({ label: i, value: i });
  }
  return options;
};

export const generateStateOptions = (estados) => {
  return estados.map((state) => {
    return { label: state.stateInitials, value: state.stateId };
  });
};

export const getStateNameById = (stateId) => {
  const stateSelected = ESTADOS.find(
    (state) => state.stateId === stateId
  );
  if(stateSelected){
    return stateSelected.stateName
  }
  return '';
};

export const getCityNameById = (cityId) => {
  const citySelected = CIDADES.find(
    (city) => city.cityId === cityId
  );
  if(citySelected){
    return citySelected.cityName
  }
  return '';
};

export const getStateAndCityByNames = (stateName, cityName) => {
  const stateIndex = ESTADOS.findIndex(
    (estado) => estado.stateName === stateName
  );
  const cityIndex = CIDADES.findIndex((cidade) => cidade.cityName === cityName);
  const stateId = stateIndex > -1 ? ESTADOS[stateIndex].stateId : null;
  const cityId = cityIndex > -1 ? CIDADES[cityIndex].cityId : null;
  return {
    stateId,
    cityId
  };
};

export const searchCitysByName = (cityName) => {
  const lowercaseCityName = cityName.toLowerCase(); 
  const cities = CIDADES.filter((city) => city.cityName.toLowerCase().includes(lowercaseCityName));
  const citysNames = cities.map((city) => ({name: city.cityName, value: city.cityId }));
  // Retorna somente os 10 primeiros resultados

  const firstTenCities = citysNames.slice(0, 10);
  return firstTenCities;
}

export const searchStatesByName = (stateName) => {
  const lowercaseStateName = stateName.toLowerCase(); 
  const states = ESTADOS.filter((state) => state.stateName.toLowerCase().includes(lowercaseStateName));
  const stateNames = states.map((state) => ({name: state.stateName, value: state.stateId }));

  const firstTenStates = stateNames.slice(0, 10);
  return firstTenStates;
}

export const formatTimeAgo = (lastDate) => {
  const now = moment();
  const lastMoment = moment(lastDate);
  const duration = moment.duration(now.diff(lastMoment));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (years > 0) return `há ${years} ano${years > 1 ? 's' : ''}`;
  if (months > 0) return `há ${months} mês${months > 1 ? 'es' : ''}`;
  if (days > 0) return `há ${days} dia${days > 1 ? 's' : ''}`;
  if (hours > 0) return `há ${hours} hora${hours > 1 ? 's' : ''}`;
  if (minutes > 0) return `há ${minutes} minuto${minutes > 1 ? 's' : ''}`;
  return `há ${seconds} segundo${seconds > 1 ? 's' : ''}`;
}

export const generateInitials = (name) => {
  const words = name.split(' ');

  let initials = '';

  for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
  }

  return initials.toUpperCase();
}

export const getColorAvatar = () => {
  const randomIndex = Math.floor(Math.random() * colorsAvatar.length);
  return colorsAvatar[randomIndex];
}
 export const getColorQualifier = (value, qualifier) => {
  let color = '#3e9e41';
  if (value && qualifier) {
    for (let rule of qualifier.rules) {
      const order = rule.initialProportion < rule.endProportion;
      if (order ? (value >= rule.initialProportion && value <= rule.endProportion) : (value <= rule.initialProportion && value >= rule.endProportion)) {
        color = rule.backgroundColor;
        break;
      }
    }
  }
  return color;
}

export const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isValidCPF = (value) => {
  let Soma;
  let Resto;
  Soma = 0;
  const strCPF = value.replace(/[^\d]/g, '');
  if (strCPF == '00000000000') return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

export const isValidCNPJ = (value) => {
  const cnpj = value.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};
export const getDate = (value) => {
  const splitDate = value.split('/');
  const month = splitDate[0];
  const year = splitDate[1];
  return {
    month,
    year
  };
};
export const valideExpiration = (value) => {
  const { month, year } = getDate(value);
  const date = moment.utc(`${year}-${month}`).add('month', 1);
  const today = moment.utc();
  return date.isValid() && date.isSameOrAfter(today);
};
export const validateCreditCard = (card) => {
  var cartoes = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})/,
    Mastercard: /^5[1-5][0-9]{14}/
  };
  for (var cartao in cartoes) if (card.match(cartoes[cartao])) return cartao;
  return false;
};
export const onlyLetters = (value) => {
  return value.replace(/[0-9 ]/g, '');
};

export const setCookie = (cname, cvalue, domain) => {
  Cookies.set(cname, cvalue, { domain, path: '/' });
};
export const deleteCookie = (cname, cvalue, domain, w = null) => {
  Cookies.remove(cname, { domain, path: '/' });
};
export const getCookie = (cname) => {
  return Cookies.get(cname);
};

export const escapeJSON = (string) => {
  var value = string || '';
  var myEscapedJSONString = value
    .replace(/\\n/g, '\\n')
    .replace(/\\'/g, '\\\'')
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, '\\&')
    .replace(/\\r/g, '\\r')
    .replace(/\\t/g, '\\t')
    .replace(/\\b/g, '\\b')
    .replace(/\\f/g, '\\f')
    .replace(/\\/g, '');
  return myEscapedJSONString;
};

/**
 * treats decimal separator and decimal places on a given number
 * @param {Number} number number to be treated
 * @param {Integer} decimals amount of decimal places to set
 * @param {Boolean} keepOriginal boolean to keep original separator
 * @returns value with decimal separator and decimal places set
 */
export const setDecimalSeparator = (
  number,
  decimals = null,
  keepOriginal = false
) => {
  if (number === null || number === undefined) return null;
  const value = decimals ? parseFloat(number).toFixed(decimals) : number;
  if (keepOriginal) return value;
  return `${value}`.replace('.', systemConfigs.POINTS_DECIMAL_SEPARATOR);
};

export const numberToString = (
  number
) => {
  if (number === null || number === undefined) return null;
  return `${number}`;
};

const measurementUnitDisplayStyle = (measurementUnit, measurementUnitClass) => {
  return measurementUnit ? (
    <label className={measurementUnitClass}>{measurementUnit}</label>
  ) : null;
};

export const stringNameAndMeasurementUnit = (
  fieldName,
  measurementUnit,
  measurementUnitClass,
  space = ' '
) => {
  return (
    <label>
      {fieldName}
      {space}
      {measurementUnitDisplayStyle(measurementUnit, measurementUnitClass)}
    </label>
  );
};

export const handleDataType = (dataType) => {
  let nameOfDataType = '';

  switch (dataType) {
    case 1:
      nameOfDataType = 'Número';
      break;
    case 2:
      nameOfDataType = 'Número Flutuante';
      break;
    case 3:
      nameOfDataType = 'Número Decimal';
      break;
    case 4:
      nameOfDataType = 'Texto';
      break;
    case 5:
      nameOfDataType = 'Lista de opções';
      break;
    case 6:
      nameOfDataType = 'Seleção Simples';
      break;
    case 7:
      nameOfDataType = 'Data e Hora';
      break;
    case 8:
      nameOfDataType = 'Data';
      break;
    case 9:
      nameOfDataType = 'Hora';
      break;
    case 10:
      nameOfDataType = 'Pesquisa';
      break;
    case 11:
      nameOfDataType = '';
      break;
    case 12:
      nameOfDataType = '';
      break;
    case 13:
      nameOfDataType = '';
      break;
    case 14:
      nameOfDataType = 'Rótulo de Informação';
      break;
    case 15:
      nameOfDataType = '';
      break;
    case 16:
      nameOfDataType = 'Texto Grande';
      break;
    case 17:
      nameOfDataType = 'Múltipla Escolha';
      break;
    case 18:
      nameOfDataType = 'Período `(Data e Hora)`';
      break;
    case 19:
      nameOfDataType = 'Período';
      break;
    case 20:
      nameOfDataType = 'Período (Hora)';
      break;
    case 21:
      nameOfDataType = '';
      break;
    case 22:
      nameOfDataType = 'Relatório';
      break;
    case 23:
      nameOfDataType = 'Escala Numérica';
      break;
    case 24:
      nameOfDataType = 'Cálculo';
      break;
    case 25:
      nameOfDataType = 'Exercícios';
      break;
    case 27:
      nameOfDataType = 'Gráfico';
      break
    case 28:
      nameOfDataType = 'Mapa corporal';
      break
    case 29:
      nameOfDataType = 'Objetivos';
      break
    default:
      nameOfDataType = 'Campo não identificado';
      break;
  }
  return nameOfDataType;
};

export { default as generalSelector } from './generalSelector';
